/* eslint-disable no-unused-vars */
export const SiteFooter = {
  init({ isFramer = false } = {}) {
    this.bindFooterMarqueeScroll();
  },

  bindFooterMarqueeScroll() {
    const marqueeEl = document.querySelector('[data-site-footer-marquee]');
    if (!marqueeEl) return;

    marqueeEl.addEventListener('scroll', () => {
      if (marqueeEl.scrollLeft > 0) {
        marqueeEl.classList.add('site-footer-marquee--scrolled');
      }
    });
  },
};

export default SiteFooter;
