/*
  To be used in the HTML response of the `<drb-autocomplete>` component when retrieving results.

  ```
    <drb-autocomplete-option>Web design</drb-autocomplete-option>
    <drb-autocomplete-option value="Custom value">Illustration</drb-autocomplete-option>
  ```

  Attributes:
    - `value` (string) - the value that will be set on the input when this option is clicked. (defaults to text content)
    - `unstyled` (boolean) - if present, the option will not have any default styling.
*/

import { LitElement, unsafeCSS } from 'lit';
import { html } from 'lit/static-html.js';
import { customElement, property } from 'lit/decorators.js';
import styles from './drb-autocomplete-option.scss?inline';

@customElement('drb-autocomplete-option')
class DrbAutocompleteOption extends LitElement {
  static styles = unsafeCSS(styles);

  @property()
  value = this.textContent?.trim() || "";

  @property({ reflect: true, type: Boolean })
  unstyled = false;

  @property({ reflect: true, type: Boolean })
  highlighted = false;

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('click', () => {
      this.dispatchEvent(new CustomEvent('drb-autocomplete-option:clicked', {
        bubbles: true,
        composed: true,
        detail: {
          value: this.value,
          target: this
        }
      }));
    });

    this.addEventListener('mouseover', () => {
      this.dispatchEvent(new CustomEvent('drb-autocomplete-option:hovered', {
        bubbles: true,
        composed: true,
        detail: {
          value: this.value,
          target: this
        }
      }));
    });

  }
  render() {
    return this.unstyled
      ? html`<slot></slot>`
      : html`
        <div class="autocomplete-option">
          <span class="autocomplete-option__text">
            <slot></slot>
          </span>
        </div>
      `;
  }
}

export { DrbAutocompleteOption };
