/*

Shows the character count for a specific input field.

```
  <drb-character-counter for="my-input-field" max="120">
  <input id="my-input-field">
```

Attributes:
  - `max` (number) - the maximum number of characters allowed in the input field.
  - `input-error-class` (string) - the optional class that will be added to the input field when the character count exceeds the `max` value.
*/

import { LitElement, unsafeCSS, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { customElement, property, state } from 'lit/decorators.js';
import styles from './drb-character-counter.scss?inline';

@customElement('drb-character-counter')
class DrbCharacterCounter extends LitElement {
  static styles = unsafeCSS(styles);

  @property({ type: Number })
  max = 80;

  @property({ attribute: 'input-error-class', type: String })
  inputErrorClass = 'error';

  @state()
  protected currentLength = 0;

  @state()
  protected inputElement: HTMLInputElement | null = null;

  connectedCallback() {
    super.connectedCallback();
    this.inputElement = (document.getElementById(this.getAttribute('for')) as HTMLInputElement);

    this.inputElement?.addEventListener('input', this._updateCounter);
    this._updateCounter();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.inputElement?.removeEventListener('input', this._updateCounter);
  }

  private _updateCounter = () => {
    this.currentLength = this.inputElement?.value?.length || 0;
    this.inputElement?.classList.toggle(this.inputErrorClass, this.currentLength > this.max);
  }

  render() {
    const classes = {
      warning: this.currentLength > this.max * 0.8,
      error: this.currentLength > this.max
    };

    return html`
      <div class="counter ${classMap(classes)}">
        ${this.currentLength}/${this.max}
      </div>
    `;
  }
}

export { DrbCharacterCounter };
