/*
  A generic dropdown component that can be composed with any trigger and content.

  ```
    <drb-dropdown name="filter-role-type" value="full-time">
      <button class="dropdown2">
        <%= svg_icon("v2-user-01") %>
        <span data-dropdown-label>Full time</span>
      </button>

      <div slot="dropdown-content">
        <drb-dropdown-option label="Role Type">Any role</drb-dropdown-option>
        <drb-dropdown-option value="full-time">Full time</drb-dropdown-option>
        <drb-dropdown-option value="part-time">Part time</drb-dropdown-option>
      </div>
    </drb-dropdown>
  ```

  Attributes:
    - `name` (string) - the name of the dropdown, used for form-associated elements.
    - `value` (string) - the initialvalue of the dropdown.
    - `flip` (boolean) - if present, the dropdown popover will flip when there is not enough space.
    - `distance` (number) - the offset distance the dropdown popover will be displayed from the trigger.
    - `fit-content` (boolean) - if present, the dropdown content will fit to its content.
    - `placement` (string) - the placement of the dropdown popover. (default: "bottom-end") https://shoelace.style/components/popup#placement
    - `no-toggle-open` (boolean) - if present, the dropdown will not toggle open when clicked.

  Methods:
    - `open()` - opens the dropdown.
    - `close()` - closes the dropdown.

  The light dom is expected to contain a single root element that acts as the
  trigger for the dropdown (typically this would be a button with `.dropdown2`).

  An element with the attribute `data-dropdown-label` will be automatically updated
  when the internal value/label changes.

  When the light dom root trigger is clicked, the dropdown content will be
  displayed in a popover.

  The dropdown content should be placed in a slot with the name "dropdown-content",
  and can contain anything.

  When using `<drb-dropdown-option>` components within this content area, the
  dropdown will automatically update its value/label when an option is clicked.

  The dropdown value/label can also be set manually e.g.
  ```
    const dropdown = document.querySelector('drb-dropdown');
    dropdown.value = 'part-time';
    dropdown.label = 'Part time'; // (optional) if using `<drb-dropdown-option>` components, the label will be automatically inferred
  ```
*/
import { LitElement, unsafeCSS, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { onNextRepaint } from '~/shared/utils/animation';
import styles from './drb-dropdown.scss?inline';
import { DrbDropdownOption } from '~/web-components/drb-dropdown-option/drb-dropdown-option';
import { DrbTypeAhead } from '~/web-components/drb-type-ahead/drb-type-ahead';

@customElement('drb-dropdown')
class DrbDropdown extends LitElement {
  static styles = unsafeCSS(styles);
  static formAssociated = true;
  internals: ElementInternals;
  isDraggingFromWithin = false;

  @property({ attribute: 'active', reflect: true })
  isActive = false;

  @property()
  name = "";

  @property({ reflect: true, type: String })
  value = "";

  @property({ reflect: true, type: String })
  label = "";

  @property({ type: Boolean })
  flip = false;

  @property({ type: Number })
  distance = 8;

  @property({ attribute: 'fit-content', type: Boolean })
  fitContent = false;

  @property({ attribute: 'placement', type: String })
  placement = "bottom-end";

  @property({ attribute: 'no-toggle-open', type: Boolean })
  noToggleOpen = false;

  @property({ type: String })
  strategy: 'absolute' | 'fixed' = 'absolute';

  get _slottedChildren() {
    const slot = this.shadowRoot.querySelector('slot');
    return slot.assignedElements({ flatten: true });
  }

  constructor() {
    super();
    // allow the dropdown value to be form-associated
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/attachInternals
    if (this.attachInternals) this.internals = this.attachInternals();
  }

  connectedCallback() {
    super.connectedCallback();
    this._syncLabel();

    // close dropdown when clicking outside of it
    document.addEventListener('click', (e) => {
      const target = (e.target as HTMLElement);

      if (!this.contains(target) && !this._slottedChildren.includes(target) && this.isActive && !this.isDraggingFromWithin) {
        this.close();
      }
    });

    // close dropdown when pressing escape
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isActive) {
        e.stopPropagation();
        this.close();
      }
    });

    document.addEventListener('keydown', (e) => {
      if (this.isActive && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
        e.stopPropagation();
        e.preventDefault();
        this.handleKeyboardNav(e.key);
      };
    })

    document.addEventListener('mouseup', () => {
      onNextRepaint(() => { this.isDraggingFromWithin = false; })
    });
  }

  updated(changedProperties: Map<string, any>) {
    // sync light DOM label element when label attribute changes
    if (changedProperties.has('label')) {
      const lightDomLabel = this.querySelector('[data-dropdown-label]');
      if (lightDomLabel && this.label) lightDomLabel.innerHTML = this.label;
    }

    if (changedProperties.has('value')) {
      // close dropdown
      this.close();

      // update form-associated value
      if (this.name) this.internals?.setFormValue(this.value);

      // sync label if it hasn't been manually set
      if (!changedProperties.has('label')) {
        this._syncLabel();
      }

      const oldAndNewValueFalsey = !changedProperties.get('value') && !this.value;

      // notify any listeners that the dropdown value has changed
      // (on first render, old value == undefined and new value == "" for some reason,
      // which triggers the change event even though the value hasn't really changed)
      if (!oldAndNewValueFalsey) {
        this.dispatchEvent(new CustomEvent('change', {
          bubbles: true,
          composed: true
        }));
      }
    }
  }

  handleKeyboardNav(key) {
    const options = Array.from(this.querySelectorAll<DrbDropdownOption>('drb-dropdown-option'));
    if (!options.length) return;

    const focusedOption = options.find(option => option === document.activeElement)
    const selectedOption = options.find(option => option.selected);

    const indexModifier = key === 'ArrowDown' ? 1 : -1;
    let nextOptionIdx = options.indexOf(focusedOption || selectedOption) + indexModifier;

    // Handles going to end of list or start of list
    if (nextOptionIdx >= options.length) {
      nextOptionIdx = 0;
    } else if (nextOptionIdx < 0) {
      nextOptionIdx = options.length - 1;
    }

    options[nextOptionIdx]?.focus();
  }

  close() {
    this.isActive = false;

    // reset any nested <drb-type-ahead> components after a slight delay
    setTimeout(() => {
      const typeAheads = Array.from<DrbTypeAhead>(this.querySelectorAll('drb-type-ahead'));
      typeAheads.forEach(typeAhead => typeAhead.reset());
    }, 300);
  }

  open() {
    this.isActive = true;
  }

  private _toggleOpen = () => {
    if (this.noToggleOpen) return;

    if (this.isActive) {
      this.close();
    } else {
      this.open();
    }
  }

  // sync the dropdown label with the selected option
  private _syncLabel = () => {
    const selectedDropdownOption = Array
      .from(this.querySelectorAll<DrbDropdownOption>('drb-dropdown-option'))
      .find(option => option.value === this.value);

    if (selectedDropdownOption) this.label = selectedDropdownOption.label;
  }

  render() {
    return html`
      <drb-popover
        active="${this.isActive || nothing}"
        flip="${this.flip || nothing}"
        strategy="${this.strategy}"
        distance="${this.distance}"
        placement="${this.placement}"
        sync="${this.fitContent ? '' : 'width'}"
        @mousedown="${() => { this.isDraggingFromWithin = true; }}"
      >
        <slot @click="${this._toggleOpen}"></slot>
        <slot
          name="dropdown-content"
          slot="popover-content"
        ></slot>
      </drb-popover>
    `;
  }
}

export { DrbDropdown };
