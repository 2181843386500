import { Editor } from '@tiptap/core'
import { LitElement, html, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import styles from './drb-rich-text-actions.scss?inline';
import boldIcon from '~/assets/icons/v2-bold-01.svg?raw';
import italicIcon from '~/assets/icons/v2-italic-01.svg?raw';
import underlineIcon from '~/assets/icons/v2-underline-01.svg?raw';
import unorderedListIcon from '~/assets/icons/v2-dotpoints-02.svg?raw';
import orderedListIcon from '~/assets/icons/v2-ordered-list.svg?raw';
import { DrbRichText } from '~/web-components/drb-rich-text/drb-rich-text';

@customElement('drb-rich-text-actions')
class DrbRichTextActions extends LitElement {
  static styles = unsafeCSS(styles);
  drbRichText?: DrbRichText = this.closest<DrbRichText>('drb-rich-text');
  editor?: Editor = this.drbRichText?.editor;

  // define the default actions, grouped by dividers
  defaultActionGroups = [
    [
      {
        title: 'Bold',
        icon: boldIcon,
        action: () => this.editor?.chain().focus().toggleBold().run(),
        isActive: () => this.editor?.isActive('bold'),
        enabled: !!this.editor?.can().toggleBold
      },
      {
        title: 'Italic',
        icon: italicIcon,
        action: () => this.editor?.chain().focus().toggleItalic().run(),
        isActive: () => this.editor?.isActive('italic'),
        enabled: !!this.editor?.can().toggleItalic
      },
      {
        title: 'Underline',
        icon: underlineIcon,
        action: () => this.editor?.chain().focus().toggleUnderline().run(),
        isActive: () => this.editor?.isActive('underline'),
        enabled: !!this.editor?.can().toggleUnderline
      }
    ],
    [
      {
        icon: unorderedListIcon,
        title: 'Unordered List',
        action: () => this.editor?.chain().focus().toggleBulletList().run(),
        isActive: () => this.editor?.isActive('bulletList'),
        enabled: !!this.editor?.can().toggleBulletList
      },
      {
        icon: orderedListIcon,
        title: 'Ordered List',
        action: () => this.editor?.chain().focus().toggleOrderedList().run(),
        isActive: () => this.editor?.isActive('orderedList'),
        enabled: !!this.editor?.can().toggleOrderedList
      }
    ]
  ];

  connectedCallback() {
    super.connectedCallback();

    // sync the ui state with the tiptap editor state
    this.editor?.on('transaction', () => {
      this.requestUpdate();
    });
  }

  // filter out disabled actions and empty groups
  private getEnabledActionGroups() {
    return this.defaultActionGroups
      .map(group => group.filter(action => action.enabled))
      .filter(group => group.length > 0);
  }

  render() {
    return html`
      <div class="rich-text-actions">
        ${this.getEnabledActionGroups().map((group, i) => html`
          ${i > 0 ? html`<div class="rich-text-actions__divider"></div>` : ''}

          ${group.map(action => html`
            <button
              class="rich-text-actions__action"
              @click=${action.action}
              title=${action.title}
              ?active=${action.isActive()}
            >
              ${unsafeHTML(action.icon)}
            </button>
          `)}
        `)}

        <slot name="custom-actions"></slot>
      </div>
    `;
  }
}

export { DrbRichTextActions };
