/**
 * DrbEmojiPicker - A emoji picker component.
 *
 * @description
 * This component toggles the visibility of the emoji picker dialog 
 * and emits a custom event when an emoji is selected. The component can be
 * used anywhere and supports interaction outside the picker, closing on escape key
 * press or when clicking outside the picker area.
 *
 * @usage
 * To use this component, add the `<drb-emoji-picker>` custom element to your template.
 * 
 * Example:
 * ```
 *  <drb-emoji-picker>
 *    <button>
 *      <%= svg_icon("smile") %>
*     </button>
 *  </drb-emoji-picker> 
 * ```
 * 
 * - The `slot` is used to define the toggle trigger for showing the emoji picker.
 * - When an emoji is selected, the component emits a `drb-emoji-picker:selected` event with
 *   the selected emoji's unicode character in the `detail.emoji`.
 *
 * @events
 * - `drb-emoji-picker:selected`: Emitted when an emoji is clicked. The selected emoji's unicode 
 *   is provided in the event's `detail.emoji`.
 *
 * @methods
 * - `toggleEmojiPicker()`: Toggles the visibility of the emoji picker.
 * - `handleOutsideClick(event)`: Handles click events outside of the emoji picker to hide it.
 * - `handleEscapeKey(event)`: Handles escape key press to hide the emoji picker.
 * - `bindEmojiClick()`: Binds a listener for the emoji picker to capture emoji selection.
 */

import { LitElement, unsafeCSS, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import styles from './drb-emoji-picker.scss?inline';

@customElement('drb-emoji-picker')
class DrbEmojiPicker extends LitElement {
  static styles = unsafeCSS(styles);

  @property({ type: Boolean })
  isVisible = false;

  @query('emoji-picker') emojiPicker!: HTMLElement;

  handleOutsideClick = (event: MouseEvent) => {
    const slot = this.shadowRoot?.querySelector('slot')?.assignedElements({ flatten: true }) as HTMLElement[];
    const target = event.composedPath()[0] as HTMLElement;
    const isOutside = this.isVisible && !this.emojiPicker.shadowRoot?.contains(target) && !slot[0].contains(target);

    if (!isOutside) return;

      this.isVisible = false;
  };
      
  handleEscapeKey = (event: KeyboardEvent) => {
    const isEscKeyPressed = event.key === 'Escape' && this.isVisible
    
    if (!isEscKeyPressed) return

    this.isVisible = false;
  };

  toggleEmojiPicker() {
    this.isVisible = !this.isVisible;
  }

  bindEmojiClick() {
    this.emojiPicker.addEventListener('emoji-click', (event: any) => {
      this.dispatchEvent(new CustomEvent('drb-emoji-picker:selected', {
        bubbles: true,
        composed: true,
        detail: {
          emoji: event.detail.unicode
        }
      }));
    });
  }

  firstUpdated() {
    if (!this.emojiPicker) return;
    this.bindEmojiClick() 

    document.addEventListener('mousedown', this.handleOutsideClick);
    document.addEventListener('keydown', this.handleEscapeKey);    
  }
  

  render() {
    return html`
      <div class="drb-emoji-picker">
        <slot @click="${this.toggleEmojiPicker}"></slot>
        <div class="drb-emoji-picker__dialog ${this.isVisible ? 'show' : 'hide'}">
          <emoji-picker class="light"></emoji-picker>
        </div>
      </div>
    `;
  }
}

export { DrbEmojiPicker };
