import { LitElement, unsafeCSS } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ref, createRef, Ref } from 'lit/directives/ref.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { html } from 'lit/static-html.js';
import chevronRightIcon from '~/assets/icons/chevron-right.svg?raw';
import styles from './drb-horizontal-overflow-arrows.scss?inline';

@customElement('drb-horizontal-overflow-arrows')
class DrbHorizontalOverflowArrows extends LitElement {
  static styles = unsafeCSS(styles);
  overflowContainer: HTMLElement = this.querySelector('[data-horizontal-overflow]');
  leftArrowRef: Ref<HTMLElement> = createRef();
  rightArrowRef: Ref<HTMLElement> = createRef();


  protected firstUpdated() {
    if (!this.overflowContainer) return;

    // update visibility on resize
    const resizeObserver = new ResizeObserver(() => {
      this.updateArrowVisibility();
    });

    resizeObserver.observe(this.overflowContainer);

    // update visibility on scroll
    this.overflowContainer.addEventListener('scroll', () => {
      this.updateArrowVisibility();
    });

    // scroll left on arrow click
    this.leftArrowRef.value?.addEventListener('click', () => {
      this.overflowContainer.scrollBy({
        left: -(this.overflowContainer.clientWidth * 0.8),
        behavior: 'smooth'
      });
    });

    // scroll right on arrow click
    this.rightArrowRef.value?.addEventListener('click', () => {
      this.overflowContainer.scrollBy({
        left: this.overflowContainer.clientWidth * 0.8,
        behavior: 'smooth'
      });
    });

    // update visibility on initial render
    this.updateArrowVisibility();
  }

  // hide/show arrows based on scroll position & container width
  private updateArrowVisibility() {
    const leftArrow = this.leftArrowRef.value;
    const rightArrow = this.rightArrowRef.value;

    const containerHasOverflow = this.overflowContainer.scrollWidth > this.overflowContainer.clientWidth;

    if (containerHasOverflow) {
      const isScrolledRight = this.overflowContainer.scrollLeft > 0
      const isScrolledLeft = this.overflowContainer.scrollLeft < (this.overflowContainer.scrollWidth - this.overflowContainer.clientWidth);

      leftArrow.style.display = isScrolledRight ? 'block' : 'none';
      rightArrow.style.display = isScrolledLeft ? 'block' : 'none';
    } else {
      leftArrow.style.display = 'none';
      rightArrow.style.display = 'none';
    }
  }

  render() {
    return html`
      <slot></slot>

      <button ${ref(this.leftArrowRef)} class="arrow-left">
        ${unsafeHTML(chevronRightIcon)}
      </button>

      <button ${ref(this.rightArrowRef)} class="arrow-right">
        ${unsafeHTML(chevronRightIcon)}
      </button>
    `;
  }
}

export { DrbHorizontalOverflowArrows };
