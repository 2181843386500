/*
  A toast component that can be used to show a toast or announcement.

  Typically would be used via the `drb-toast` helper function in `/web-components/drb-toast/drb-toast-helpers.js`

  ```
    DrbToast.open({
      title: 'Success',
      messageHtml: 'Your changes have been saved.',
    });
  ```

  But can be used in rails if needed
  ```
    <drb-toast id="my-new-toast">
      <div slot="icon">
        The svg you want to appear as the icon in the toast
      </div>

      <div slot="message">
        The html you want to appear as the message in the toast
      </div>
    </drb-toast>
  ```

  This relies on the `sl-alert` component from the Shoelace library.
*/

import { LitElement, unsafeCSS, html } from 'lit';
import { customElement, property, queryAssignedNodes, queryAssignedElements } from 'lit/decorators.js';
import Cookies from 'js-cookie';
import SLAlert from '@shoelace-style/shoelace/dist/components/alert/alert.component.js';
import styles from './drb-toast.scss?inline';

@customElement('drb-toast')
class DrbToast extends LitElement {
  static styles = unsafeCSS(styles);
  cookieName = 'drb-toast-dismissed';

  @queryAssignedNodes({ slot: 'icon', flatten: true })
  _iconHtmlNodes!: Array<HTMLElement>;

  @queryAssignedNodes({ slot: 'message', flatten: true })
  _messageHtmlNodes!: Array<HTMLElement>;

  @property({ type: String })
  title = '';

  @property({ type: String })
  variant: 'primary' | 'success' = 'primary';

  @property({ attribute: 'only-once', type: Boolean })
  onlyOnce = false;

  @property({ attribute: 'hide-close', type: Boolean, reflect: true })
  hideClose = false;

  @property({ type: Number })
  duration;

  firstUpdated() {
    // if the toast has been dismissed, don't show it
    if (this.onlyOnce && this._getDismissedToastIds().includes(this.id)) return;

    const messageHtml = this._getNodesHtml(this._messageHtmlNodes);
    const iconHtml = this._getNodesHtml(this._iconHtmlNodes);

    this._createAlert(messageHtml, iconHtml);
  }

  private _getNodesHtml(nodes: Array<HTMLElement>) {
    return nodes.map(node => node.innerHTML).join('') || '';
  }

  private _createAlert(messageHtml: string, iconHtml: string) {
    const titleHtml = this.title ? `<div class="drb-toast__title">${this.title}</div>` : '';

    const alert: Partial<SLAlert> = Object.assign(document.createElement('sl-alert'), {
      id: this.id,
      closable: !this.hideClose,
      duration: this.duration,
      variant: this.variant,
      innerHTML: `
        <div slot="icon">${iconHtml}</div>

        <div class="drb-toast drb-toast--${this.variant}">
          ${titleHtml}
          ${messageHtml}
        </div>
      `
    });

    document.body.append(alert as Node);

    alert.addEventListener('sl-after-hide', () => {
      // self destruct
      this.remove();
    });

    alert.addEventListener('sl-hide', () => {
      if (this.onlyOnce) this._setCookieOnDismiss();
    });

    alert.addEventListener('click', (e) => {
      const target = (e.target as HTMLElement).closest('[data-toast-close]');
      if (target) alert.hide();
    });

    return alert.toast();
  }

  private _getDismissedToastIds(): Array<string> {
    return JSON.parse(Cookies.get(this.cookieName) || '[]');
  }

  private _setCookieOnDismiss() {
    const dismissedIds = this._getDismissedToastIds();
    dismissedIds.push(this.id);
    Cookies.set(this.cookieName, JSON.stringify(dismissedIds), { expires: 180 });
  }

  render() {
    return html`
      <slot name="icon"></slot>
      <slot name="message"></slot>
    `
  }
}

export { DrbToast };
