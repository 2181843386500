/*
  To be used as a child of <drb-dropdown> within the `dropdown-content` slot.

  ```
    <drb-dropdown value="full-time">
      <button class="dropdown2">
        <span data-dropdown-label></span>
      </button>

      <div slot="dropdown-content">
        <drb-dropdown-option label="Role Type">Any role</drb-dropdown-option>
        <drb-dropdown-option value="full-time">Full time</drb-dropdown-option>
        <drb-dropdown-option value="part-time">Part time</drb-dropdown-option>
      </div>
    </drb-dropdown>
  ```

  Attributes:
    - `value` (string) - the value that will be set on the dropdown when this option is clicked.
    - `label` (string) - the label that will be displayed in the dropdown when this option is clicked.
    - `href` (string) - if present, will make dropdown option behave like a link.
    - `download` (string) - if present, will add a `download` attribute to the link.
    - `no-selected` (boolean) - if present, the option will not show a selected state when it is selected.
    - `unstyled` (boolean) - if present, the option will not have any default styling.
*/

import { LitElement, unsafeCSS, nothing } from 'lit';
import { html, literal } from 'lit/static-html.js';
import { customElement, property } from 'lit/decorators.js';
import { DrbDropdown } from '~/web-components/drb-dropdown/drb-dropdown';
import styles from './drb-dropdown-option.scss?inline';

@customElement('drb-dropdown-option')
class DrbDropdownOption extends LitElement {
  static styles = unsafeCSS(styles);
  dropdown: DrbDropdown | null = this.closest('drb-dropdown');

  @property({ reflect: true, type: Boolean })
  unstyled = false;

  @property({ reflect: true, type: Boolean })
  selected = false;

  @property({ attribute: 'no-selected', type: Boolean })
  noSelected = false;

  @property()
  label = this.textContent?.trim() || "";

  @property()
  value = this.textContent?.trim() || "";

  @property()
  href = "";

  @property()
  download = "";

  connectedCallback() {
    super.connectedCallback();
    if (!this.dropdown) return;

    // sync selected state on load
    this._syncSelected();

    // update dropdown value/label when option is clicked
    this.addEventListener('click', () => {
      if (this.href) return;
      this.dropdown.value = this.value;
      this.dropdown.label = this.label;
      this.dropdown.close();
    });

    // update selected state when dropdown value changes
    this.dropdown.addEventListener('change', this._syncSelected.bind(this));
  }

  focus() {
    const nextFocusableElement = this.renderRoot.querySelector('a, button') as HTMLElement;
    nextFocusableElement?.focus();
  }

  private _syncSelected() {
    // don't update if noSelected is set (we don't want to show a selected state for this option)
    if (this.noSelected) return;
    this.selected = this.dropdown.value === this.value;
  }

  render() {
    const tag = this.href ? literal`a` : literal`button`;

    return this.unstyled
      ? html`<slot></slot>`
      : html`
        <${tag}
          class="dropdown-option"
          href="${this.href || nothing}"
          download="${this.download || nothing}"
        >
          <span class="dropdown-option__text">
            <slot></slot>
          </span>
        </${tag}>
      `;
  }
}

export { DrbDropdownOption };
