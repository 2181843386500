import { LitElement, unsafeCSS } from 'lit';
import { html } from 'lit/static-html.js';
import { customElement, property, state } from 'lit/decorators.js';
import styles from './drb-option-group.scss?inline';

@customElement('drb-option-group')
class DrbOptionGroup extends LitElement {
  static styles = unsafeCSS(styles);

  @state() listOrder = 0;

  @property({ type: String})
  label = '';

  render() {
    return html`
      <div
        class="option-group"
        style=${`order: ${this.listOrder}`}
      >
        <div class="option-group__container">
          <div class="option-group__label">
            ${this.label}
          </div>
        </div>

        <slot></slot>
      </div>
    `;
  }
}

export { DrbOptionGroup };
