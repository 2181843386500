/*
  Add's custom video controls to a video element.

  ```
    <drb-video>
      <video
        autoplay
        muted
        loop
        src="..."
        poster="..."
      ></video>
    </drb-video>
  ```

  Attributes:
    - `hide-play-pause`: Hides the play/pause button
    - `hide-volume`: Hides the volume button
    - `toggle-play-on-click`: Toggles play/pause when clicking on the video
*/

import { LitElement, html, unsafeCSS } from 'lit';
import { customElement, eventOptions, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ref, createRef, Ref } from 'lit/directives/ref.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import buttonStyles from '~/../assets/stylesheets/components/_buttons-v2-core.scss?inline';
import pauseIcon from '~/assets/icons/v2-pause-filled.svg?raw';
import playIcon from '~/assets/icons/v2-play-filled.svg?raw';
import volumeMaxIcon from '~/assets/icons/v2-volume-max-filled.svg?raw';
import volumeMutedIcon from '~/assets/icons/v2-volume-muted-filled.svg?raw';
import styles from './drb-video.scss?inline';

@customElement('drb-video')
class DrbVideo extends LitElement {
  static styles = unsafeCSS([styles, buttonStyles]);
  videoEl: HTMLVideoElement | null;

  progressAnimationId;
  progressBarRef: Ref<HTMLDivElement> = createRef();
  wasPaused = false;
  videoHasLoaded = false;

  @property({ attribute: 'hide-play-pause', type: Boolean })
  hidePlayPause = false;

  @property({ attribute: 'hide-volume', type: Boolean })
  hideVolume = false;

  @property({ attribute: 'toggle-play-on-click', type: Boolean })
  togglePlayOnClick = false;

  @state()
  isPlaying = false;

  @state()
  isMuted = true;

  @state()
  isDragging = false;

  @state()
  duration = 0;

  @state()
  progressPercent = 0;

  @state()
  hasAudio = false;

  connectedCallback() {
    super.connectedCallback();

    this.videoEl = this.querySelector('video');
    if (!this.videoEl) return;

    if (!this.videoEl.poster) {
      console.warn('drb-video: No poster image set on video element');
    }

    this.videoEl.addEventListener('play', this.syncPlayState.bind(this));
    this.videoEl.addEventListener('pause', this.syncPlayState.bind(this));
    this.videoEl.addEventListener('volumechange', this.syncMutedState.bind(this));
    this.videoEl.addEventListener('loadedmetadata', this.handleMetadataLoaded.bind(this));
    this.videoEl.addEventListener('loadeddata', this.checkIfVideoHasLoaded.bind(this));
    this.videoEl.addEventListener('timeupdate', this.handleTimeUpdate.bind(this));

    const isTouchDevice = matchMedia('(hover: none)').matches;

    if (!isTouchDevice && this.togglePlayOnClick) {
      this.videoEl.addEventListener('click', this.togglePlayPause.bind(this));
    }

    // Remove default controls
    this.videoEl.removeAttribute('controls');

    // Sync initial states on load
    this.syncPlayState();
    this.syncMutedState();
    this.syncDuration();
    this.checkForAudio();
    this.checkIfVideoHasLoaded();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeDragListeners();
  }

  private checkForAudio() {
    this.hasAudio = (this.videoEl as any).mozHasAudio || !!(this.videoEl as any).webkitAudioDecodedByteCount || !!(this.videoEl as any).audioTracks?.length;
  }

  private checkIfVideoHasLoaded = () => {
    if (this.videoHasLoaded) return true;

    // If some frames have loaded, or readyState is high enough
    const isLoaded = (this.videoEl.readyState >= 2 // HAVE_CURRENT_DATA or better
      || this.videoEl.currentTime > 0
    );

    if (isLoaded) {
      this.videoHasLoaded = true;
      this.checkForAudio();
      this.dispatchEvent(new Event('video-loaded', { bubbles: true, composed: true }));
    }
  }

  private syncDuration() {
    this.duration = this.videoEl.duration || 0;
  }

  private syncPlayState() {
    const hasChanged = this.isPlaying !== !this.videoEl.paused;
    if (!hasChanged) return;

    this.isPlaying = !this.videoEl.paused;
    this.updateProgress();
  }

  private syncMutedState() {
    this.isMuted = this.videoEl.muted;
  }

  private handleTimeUpdate() {
    this.checkIfVideoHasLoaded();
    this.updateProgress();
  }

  private handleMetadataLoaded() {
    this.syncDuration();
    this.checkIfVideoHasLoaded();
  }

  // Progress bar dragging - start (touch and mouse)
  @eventOptions({ passive: false })
  private handleProgressDragStart (event: MouseEvent | TouchEvent) {
    event.preventDefault();
    this.startDragging();

    // Get initial position and update video time
    this.updateVideoTimeFromEvent(event);

    // Add the appropriate event listeners based on event type
    if (event instanceof TouchEvent) {
      document.addEventListener('touchmove', this.handleProgressDragMove, { passive: false });
      document.addEventListener('touchend', this.handleProgressDragEnd);
      document.addEventListener('touchcancel', this.handleProgressDragEnd);
    } else {
      document.addEventListener('mousemove', this.handleProgressDragMove);
      document.addEventListener('mouseup', this.handleProgressDragEnd);
    }
  }

  // Progress bar dragging - drag (touch and mouse)
  private handleProgressDragMove = (event: MouseEvent | TouchEvent) => {
    if (!this.isDragging) return;
    event.preventDefault();

    this.updateVideoTimeFromEvent(event);
  }

  // Progress bar dragging - end (touch and mouse)
  private handleProgressDragEnd = (event: MouseEvent | TouchEvent) => {
    if (!this.isDragging) return;

    this.updateVideoTimeFromEvent(event);
    this.stopDragging();
  }

  private startDragging() {
    this.isDragging = true;
    this.wasPaused = this.videoEl.paused;

    if (!this.wasPaused) {
      this.videoEl.pause();
    }
  }

  private stopDragging() {
    if (!this.isDragging) return;

    this.isDragging = false;
    this.removeDragListeners();

    // If the video was playing before dragging started, resume playback
    if (!this.wasPaused) {
      this.videoEl.play();
    }
  }

  private removeDragListeners() {
    // Remove mouse listeners
    document.removeEventListener('mousemove', this.handleProgressDragMove);
    document.removeEventListener('mouseup', this.handleProgressDragEnd);

    // Remove touch listeners
    document.removeEventListener('touchmove', this.handleProgressDragMove);
    document.removeEventListener('touchend', this.handleProgressDragEnd);
    document.removeEventListener('touchcancel', this.handleProgressDragEnd);
  }

  // Seek video to a new time based off the users mouse/touch interaction
  private updateVideoTimeFromEvent(event: MouseEvent | TouchEvent) {
    if (!this.progressBarRef.value) return;

    const rect = this.progressBarRef.value.getBoundingClientRect();
    let clientX: number;

    // Determine if it's a touch or mouse event and get the appropriate clientX
    if (event instanceof TouchEvent) {
      const touch = event.touches[0] || event.changedTouches[0];
      if (!touch) return;

      clientX = touch.clientX;
    } else {
      clientX = event.clientX;
    }

    // Calculate position with bounds checking
    let offsetX = Math.max(0, Math.min(clientX - rect.left, rect.width));

    // Prevent video flickering bug when looping is enabled and scrubbing to the end
    const percent = Math.min(offsetX / rect.width, 0.999);

    // Update video time
    this.videoEl.currentTime = percent * this.duration;
    this.updateProgress();
  }

  private updateProgress() {
    cancelAnimationFrame(this.progressAnimationId);
    this.renderProgressAnimationFrame();
  }

  private renderProgressAnimationFrame = () => {
    if (!this.duration) return;

    this.progressPercent = Math.min(this.videoEl.currentTime / this.duration, 1.0);

    // Recursively update progress bar if the video is playing
    if (this.isPlaying) {
      this.progressAnimationId = requestAnimationFrame(this.renderProgressAnimationFrame);
    }
  }

  togglePlayPause() {
    if (this.videoEl.paused) {
      this.videoEl.play();
    } else {
      this.videoEl.pause();
    }
  }

  toggleMuteUnmute() {
    this.videoEl.muted = !this.videoEl.muted;
  }

  render() {
    return html`
      <div class="video">
        <slot></slot>

        <div class="controls">
          <button
            class="${classMap({
              'btn2': true,
              'btn2--circle': true,
              'btn2--secondary-alt': true,
              'play-btn': !this.isPlaying,
            })}"
            @click="${this.togglePlayPause}"
            ?hidden="${this.hidePlayPause}"
            aria-label="${this.isPlaying ? 'Pause' : 'Play'}"
          >
            ${this.isPlaying ? unsafeHTML(pauseIcon) : unsafeHTML(playIcon)}
          </button>

          <button
            class="btn2 btn2--circle btn2--secondary-alt"
            @click="${this.toggleMuteUnmute}"
            ?hidden="${this.hideVolume || !this.hasAudio}"
            aria-label="${this.isMuted ? 'Unmute' : 'Mute'}"
          >
            ${this.isMuted ? unsafeHTML(volumeMutedIcon) : unsafeHTML(volumeMaxIcon)}
          </button>

          <div
            class="progress-bar"
            @mousedown="${this.handleProgressDragStart}"
            @touchstart="${this.handleProgressDragStart}"
            @click="${this.updateVideoTimeFromEvent}"
            ${ref(this.progressBarRef)}
          >
            <div class="progress-bar__container">
              <div
                class="progress-bar__fill"
                style="--progress-percent: ${this.progressPercent * 100}%"
              ></div>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

export { DrbVideo };
