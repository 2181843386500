/*
  Add's an autocomplete popover to any input field.

  ```
    <drb-autocomplete query-path="...">
      <input type="text">
    </drb-autocomplete>
  ```

  The `query-path` should respond with HTML composed of `<drb-autocomplete-option>` elements.

  Attributes:
    - `query-path` (string) - the path to fetch the results from.
    - `query-key` (string) - the query parameter key to use when sending the search query. (default: "q")
    - `require-selection` (boolean) - if present, the input will be cleared if the value is not in the known values list. (default: false)
    - `placement` (string) - the placement of the popover. (default: "bottom-start") https://shoelace.style/components/popup#placement
    - `strategy` (string) - the shoelace positioning strategy. (default: "absolute") https://shoelace.style/components/popup#positioning-strategy
*/

import { LitElement, html, unsafeCSS, nothing } from 'lit';
import { ref, createRef, Ref } from 'lit/directives/ref.js';
import { customElement, property } from 'lit/decorators.js';
import styles from './drb-autocomplete.scss?inline';
import { DrbTypeAhead } from '~/web-components/drb-type-ahead/drb-type-ahead';
import { DrbAutocompleteOption } from '~/web-components/drb-autocomplete-option/drb-autocomplete-option';

@customElement('drb-autocomplete')
class DrbAutocomplete extends LitElement {
  static styles = unsafeCSS(styles);
  typeAheadRef: Ref<DrbTypeAhead> = createRef();
  inputEl: HTMLInputElement | null;
  knownValues: string[] = [];

  @property({ attribute: 'active', reflect: true })
  isActive = false;

  @property({ attribute: 'query-path', type: String })
  queryPath = '';

  @property({ attribute: 'query-key', type: String })
  queryKey = 'q';

  @property({ attribute: 'require-selection', type: Boolean })
  requireSelection = false;

  @property({ attribute: 'placement', type: String })
  placement = "bottom-start";

  @property({ type: String })
  strategy: 'absolute' | 'fixed' = 'absolute';

  connectedCallback() {
    super.connectedCallback();

    this.inputEl = this.querySelector('input');
    this.knownValues = [this.inputEl?.value || ''];

    if (!this.inputEl) return;

    // disable browser autocomplete
    this.inputEl.setAttribute("autocomplete", "off");

    // highlight option & fill input on keyboard navigation
    this.addEventListener('keydown', (e) => {
      if (this.isActive && (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Tab')) {
        e.stopPropagation();
        e.preventDefault();
        this._handleKeyboardNav(e.key === 'Tab' ? 'ArrowDown' : e.key);
      };
    });

    // highlight option on hover
    this.addEventListener('drb-autocomplete-option:hovered', (e: CustomEvent) => {
      const option = e.detail.target as DrbAutocompleteOption;
      this._highlightOption(option);
    });

    // select option on click
    this.addEventListener('drb-autocomplete-option:clicked', (e: CustomEvent) => {
      const option = e.detail.target as DrbAutocompleteOption;
      this._selectOption(option);
    });

    // update visibility when type-ahead content is updated
    this.addEventListener('drb-type-ahead-updated', () => {
      this._updateVisibility();
      this._updateKnownValues();
    });

    // If `requireSelection` is enabled, clear the input if the value is not in the known values list.
    // Because `blur` happens before the `click` event (e.g. when clicking an option), we need to wait
    // a bit before checking the value to avoid a flicker. (An animation frame is not enough)
    this.inputEl.addEventListener('blur', () => {
      setTimeout(() => {
        if (this.requireSelection && !this.knownValues.includes(this.inputEl.value)) {
          this.inputEl.value = '';
          this.inputEl.dispatchEvent(new Event('change', { bubbles: true }));

          this.dispatchEvent(new CustomEvent('drb-autocomplete:invalid-input', {
            bubbles: true,
            composed: true,
          }));
        }
      }, 100);
    });


    // select option on enter
    this.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' && this.isActive) {
        e.stopPropagation();
        e.preventDefault();

        const highlightedOption = this.renderRoot.querySelector<DrbAutocompleteOption>('drb-autocomplete-option[highlighted]');
        this._selectOption(highlightedOption);
      }
    });

    // close autocomplete when clicking outside of it
    document.addEventListener('click', (e) => {
      const target = (e.target as HTMLElement);

      if (!this.contains(target) && this.isActive) {
        this.close();
      }
    });

    // close autocomplete when pressing escape
    this.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isActive) {
        e.stopPropagation();
        this.close();
      }
    });
  }

  firstUpdated() {
    if (!this.typeAheadRef.value) return;

    // manually assign the input element to the type-ahead component (get around shadow dom)
    this.typeAheadRef.value.input = this.inputEl;
    this.typeAheadRef.value.initialize();
  }

  private _highlightOption(option: DrbAutocompleteOption) {
    if (!option) return;

    // unhighlight all other options
    const options = this.renderRoot.querySelectorAll<DrbAutocompleteOption>('drb-autocomplete-option');
    options.forEach((option) => option.highlighted = false);

    // highlight the selected option
    option.highlighted = true;
  }

  private _selectOption(option: DrbAutocompleteOption) {
    if (!option) return;

    this.inputEl.value = option.value;
    this.inputEl.dispatchEvent(new Event('change', { bubbles: true }));
    this.inputEl.blur();
    this.close();
  }

  // show/hide the autocomplete popover
  private _updateVisibility() {
    const containsFocus = this.contains(document.activeElement);

    if (containsFocus && this.inputEl.value && this.typeAheadRef.value?.contentEl.innerHTML) {
      this.open();
    } else {
      this.close();
    }
  }

  private _updateKnownValues() {
    const options = this.renderRoot.querySelectorAll<DrbAutocompleteOption>('drb-autocomplete-option');

    options.forEach(option => {
      if (!this.knownValues.includes(option.value)) {
        this.knownValues.push(option.value);
      }
    });
  }

  private _handleKeyboardNav(key) {
    const options = Array.from(this.renderRoot.querySelectorAll<DrbAutocompleteOption>('drb-autocomplete-option'));
    if (!options.length) return;

    const highlightedOption = options.find(option => option.highlighted);

    const indexModifier = key === 'ArrowDown' ? 1 : -1;
    let nextOptionIdx = options.indexOf(highlightedOption) + indexModifier;

    // loop to the beginning/end of list when needed
    if (nextOptionIdx >= options.length) {
      nextOptionIdx = 0;
    } else if (nextOptionIdx < 0) {
      nextOptionIdx = options.length - 1;
    }

    const nextOption = options[nextOptionIdx];
    if (!nextOption) return;

    this._highlightOption(nextOption);
    this.inputEl.value = nextOption.value;
  }

  open() {
    this.isActive = true;
  }

  close() {
    this.isActive = false;
    this.typeAheadRef.value?.abort();
  }

  render() {
    return html`
      <drb-type-ahead
        ${ref(this.typeAheadRef)}
        query-path="${this.queryPath}"
        query-key="${this.queryKey}"
      >
        <drb-popover
          active="${this.isActive || nothing}"
          strategy="${this.strategy}"
          distance="8"
          placement="${this.placement}"
          sync="width"
        >
          <slot></slot>

          <div
            data-type-ahead-content
            slot="popover-content"
          ></div>
        </drb-dropdown>
      </drb-type-ahead>
    `;
  }
}

export { DrbAutocomplete };
