// Binding to document to pick up items added to DOM afterward, a la jQuery's `.live()`.
document.addEventListener('click', (event) => {
  // show dialog
  if (event.target.closest('[data-sl-dialog-show]')) {
    const dialog = document.querySelector(`sl-dialog#${event.target.dataset.slDialogShow}`);
    dialog.show();
  }

  // hide dialog (via custom close buttons)
  if (event.target.closest('[data-sl-dialog-hide]')) {
    const dialog = event.target.closest('sl-dialog');
    dialog.hide();
  }
});
