/*
  A composable rich text editor w/ Tiptap under the hood.

  ```
    <drb-rich-text name="message" placeholder="Write your message...">
      <div class="input2 input2--container-mode">
        <drb-rich-text-actions></drb-rich-text-actions>

        <drb-rich-text-content>
          <p>Initial content html</p>
        </drb-rich-text-content>
      </div>
    </drb-rich-text>
  ```
  <drb-rich-text> is an unstyled container (with `display: contents`) that creates
  the Tiptap editor and manages the editor's value.

  <drb-rich-text-actions> can be placed anywhere inside the <drb-rich-text> element
  to add a toolbar with actions.

  <drb-rich-text-content> can be placed anywhere inside the <drb-rich-text> element
  and is the editable text container where the editor initializes itself on.

  Attributes:
    - `name` (string) - the name of the field, used for form-associated elements.
    - `placeholder` (string) - the placeholder text for the editor.
    - `disabled-actions` (string) - a comma-separated list of actions to disable. (e.g. "bold,italic,underline,lists")

  Properties:
    - `value` (string) - the current value of the editor.
    - `editor` (Tiptap Editor) - the Tiptap editor instance.
*/

import { Editor, Extensions } from '@tiptap/core'
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { onNextRepaint } from '~/shared/utils/animation';

@customElement('drb-rich-text')
class DrbRichText extends LitElement {
  richTextContainer?: HTMLElement = this.querySelector('drb-rich-text-content');
  editor?: Editor = null;
  form?: HTMLFormElement = this.closest('form');

  get value(): string {
    return this.editor?.getHTML() || '';
  }

  set value(newValue: string) {
    if (this.editor) {
      this.editor.commands.setContent(newValue, true);
    }
  }

  get textValue(): string {
    return this.richTextContainer?.textContent || '';
  }

  @property()
  name = '';

  @property()
  placeholder = '';

  @property({
    attribute: 'disabled-actions',
    converter: {
      fromAttribute: (disabledActions: string) => disabledActions.split('|'),
      toAttribute: (disabledActions: string[]) => disabledActions.join('|')
    }
  })
  disabledActions: string | string[] = '';

  connectedCallback() {
    super.connectedCallback();
    if (!this.richTextContainer) return;

    // get the initial content and clear the container
    const initialContent = this.richTextContainer.innerHTML;
    this.richTextContainer.innerHTML = '';

    // build list of extensions
    const extensions: Extensions = [Document, Paragraph, History, Text];

    const extensionMap = {
      bold: Bold,
      italic: Italic,
      underline: Underline,
      lists: [BulletList, ListItem, OrderedList]
    };

    // add extensions to the list if they are not disabled by the user
    Object.entries(extensionMap).forEach(([action, exts]) => {
      if (!this.disabledActions.includes(action)) {
        extensions.push(...(Array.isArray(exts) ? exts : [exts]));
      }
    });

    // create the tiptap editor
    this.editor = new Editor({
      element: this.richTextContainer,
      extensions: [
        ...extensions,
        Placeholder.configure({ placeholder: this.placeholder })
      ],
      content: initialContent,
    });

    this.bindEvents();
  }

  private bindEvents() {
    let valueHasChanged = false;

    // update the value when the editor content changes & emit input event
    this.editor?.on('update', () => {
      valueHasChanged = true;

      this.dispatchEvent(new CustomEvent('input', {
        bubbles: true,
        composed: true
      }));
    });

    // emit change event when the editor loses focus and the value has changed.
    // We wait for the next repaint since the active element becomes the `body` momentarily
    this.editor?.on('blur', () => {
      onNextRepaint(() => {
        if (!valueHasChanged || this.contains(document.activeElement)) return;
        valueHasChanged = false;

        this.dispatchEvent(new CustomEvent('change', {
          bubbles: true,
          composed: true
        }));
      });
    });

    // connect the value to form data (if needed)
    if (this.form && this.name) {
      this.form.addEventListener('formdata', ({ formData }) => {
        formData.append(this.name, this.value);
      });
    }

    // improve ux by focusing the editor when the user clicks on the container edges (e.g. padding)
    this.richTextContainer.addEventListener('click', () => {
      if (!this.editor?.isFocused) {
        this.editor?.commands.focus();
      }
    });
  }

  render() {
    return html`
      <slot></slot>
    `;
  }
}

export { DrbRichText };
