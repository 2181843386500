/*
  Confirmation dialog component

  See `./drb-confirm-helpers.js` for programmatic usage
*/

import { LitElement, unsafeCSS, nothing } from 'lit';
import { literal, html } from 'lit/static-html.js';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ref, createRef, Ref } from 'lit/directives/ref.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { onNextRepaint } from '~/shared/utils/animation';
import buttonStyles from '~/../assets/stylesheets/components/_buttons-v2-core.scss?inline';
import styles from './drb-confirm.scss?inline';
import { DrbDialog } from '../drb-dialog/drb-dialog';

@customElement('drb-confirm')
class DrbConfirm extends LitElement {
  static styles = unsafeCSS([styles, buttonStyles]);
  dialogRef: Ref<DrbDialog> = createRef();

  @property({ type: Boolean })
  centered = false;

  @property({ attribute: 'icon-inline', type: Boolean })
  iconInline = false;

  @property({ type: String })
  iconHtml;

  @property({ type: String })
  title = 'Are you sure?';

  @property({ type: String })
  message;

  @property({ attribute: 'confirm-text', type: String })
  confirmText = 'Confirm';

  @property({ attribute: 'confirm-class', type: String })
  confirmClass = 'btn2--primary';

  @property({ attribute: 'confirm-href', type: String })
  confirmHref = "";

  @property({ attribute: 'confirm-download', type: String })
  confirmDownload = "";

  @property({ type: Function })
  onConfirm = (confirmDialog: DrbConfirm):boolean | void => {};

  @property({ attribute: 'cancel-text', type: String })
  cancelText = 'Cancel';

  @property({ attribute: 'cancel-class', type: String })
  cancelClass = 'btn2--tertiary';

  @property({ attribute: 'cancel-href', type: String })
  cancelHref = "";

  @property({ type: Function })
  onCancel = (confirmDialog: DrbConfirm) => {};

  @property({ type: String })
  footerHtml = ""

  @state()
  actionCompleted = false;

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('drb-dialog-closed', () => {
      if (!this.actionCompleted) this._handleCancel();

      // self destruct
      setTimeout(() => {
        this.remove();
      }, 500);
    });
  }

  firstUpdated() {
    onNextRepaint(() => {
      this.dialogRef.value?.open();
    });
  }

  close() {
    this.dialogRef.value?.close();
  }

  private _handleCancel() {
    this.actionCompleted = true;
    this.onCancel(this);
    this.close();
  }

  private async _handleConfirm() {
    const confirmBtn = this.dialogRef.value?.querySelector('[data-action="confirm"]');
    const cancelBtn = this.dialogRef.value?.querySelector('[data-action="cancel"]');
    confirmBtn?.classList.add('btn2--loading');
    confirmBtn?.toggleAttribute('disabled', true);
    cancelBtn?.toggleAttribute('disabled', true);

    const shouldClose = await this.onConfirm(this);

    confirmBtn?.classList.remove('btn2--loading');
    confirmBtn?.removeAttribute('disabled');
    cancelBtn?.removeAttribute('disabled');

    if (shouldClose !== false) {
      this.actionCompleted = true;
      this.close();
    }
  }

  setIconHtml(e) {
    const childNodes = e.target.assignedNodes({flatten: true});
    this.iconHtml = childNodes?.[0]?.innerHTML;
  }

  render() {
    const confirmTag = this.confirmHref ? literal`a` : literal`button`;
    const cancelTag = this.cancelHref ? literal`a` : literal`button`;

    return html`
      <drb-dialog
        ${ref(this.dialogRef)}
        prevent-light-dismiss
      >
        <slot name="icon-html" @slotchange=${this.setIconHtml} hidden></slot>

        <div
          class="${classMap({
            'confirm': true,
            'confirm--centered': this.centered,
          })}"
        >
          <div
            class="${classMap({
              'confirm__header': true,
              'confirm__header--centered': this.centered,
              'confirm__header--inline': this.iconInline,
            })}"
          >
            <div class="confirm__icon" ?hidden=${!this.iconHtml}>
              ${unsafeHTML(this.iconHtml)}
            </div>

            <div class="confirm__title">${this.title}</div>
          </div>

          <div class="confirm__message" ?hidden=${!this.message}>
            ${unsafeHTML(this.message)}
          </div>

          <slot></slot>

          <div class="confirm__button-group">
            <${cancelTag}
              ?hidden=${!this.cancelText}
              href="${this.cancelHref || nothing}"
              class="btn2 btn2--medium ${this.cancelClass}"
              @click="${this._handleCancel}"
              data-action="cancel"
            >
              ${this.cancelText}
            </${cancelTag}>

            <${confirmTag}
              ?hidden=${!this.confirmText}
              href="${this.confirmHref || nothing}"
              download="${this.confirmDownload || nothing}"
              class="btn2 btn2--medium ${this.confirmClass}"
              @click="${this._handleConfirm}"
              data-action="confirm"
            >
              ${this.confirmText}
            </${confirmTag}>
          </div>

          <div class="confirm__footer-html" ?hidden=${!this.footerHtml}>
            ${unsafeHTML(this.footerHtml)}
          </div>
        </div>
      </drb-dialog>
    `;
  }
}

export { DrbConfirm };
