/*
Add's a type-ahead popover to an input field that queries the Clearbit API for company names.

```
  <drb-clearbit>
    <input type="text">
  </drb-clearbit>
```
*/

import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ref, createRef, Ref } from 'lit/directives/ref.js';
import { DrbTypeAhead } from '~/web-components/drb-type-ahead/drb-type-ahead';
import { DrbDropdown } from '~/web-components/drb-dropdown/drb-dropdown';

interface ClearbitCompany {
  name: string;
  domain: string;
  logo: string;
}

@customElement('drb-clearbit')
class DrbClearbit extends LitElement {
  typeAheadRef: Ref<DrbTypeAhead> = createRef();
  dropdownRef: Ref<DrbDropdown> = createRef();
  inputEl: HTMLInputElement | null;
  companies: ClearbitCompany[] = [];

  connectedCallback() {
    super.connectedCallback();
    this.inputEl = this.querySelector('input');
    this.inputEl?.addEventListener('focus', this._updateVisibility.bind(this));
  }

  firstUpdated() {
    // manually assign the input element to the type-ahead component (get around shadow dom)
    this.typeAheadRef.value.input = this.inputEl;
    this.typeAheadRef.value.initialize();

    // transform the content (json response) of the type-ahead component
    this.typeAheadRef.value.transformContent = (companies: ClearbitCompany[]) => {
      // get unique companies (prevent duplicates) and store for later use
      this.companies = companies.filter((company, index, self) =>
        index === self.findIndex((t) => (
          t.name === company.name
        ))
      );

      if (!this.companies.length) return '';

      // return the companies as dropdown options
      return this.companies.map((company) => (`
        <drb-dropdown-option>${company.name}</drb-dropdown-option>
      `)).toString().replace(/,/g, '');
    }
  }

  // show/hide the dropdown
  private _updateVisibility() {
    if (this.inputEl !== document.activeElement) return;

    if (this.inputEl.value && this.typeAheadRef.value?.contentEl.innerHTML) {
      this.dropdownRef.value?.open();
    }
    else {
      this.dropdownRef.value?.close();
    }
  }

  private _getCompany(name: string) {
    return this.companies.find((company) => company.name.toLowerCase() === name.toLowerCase());
  }

  private _selectCompany(e) {
    // ignore if the event target is not the dropdown directly
    // (e.g. `change` is also fired from the native input element)
    if (!(e.target instanceof DrbDropdown)) return;

    const selectedCompanyName = this.dropdownRef.value?.value;
    const company = this._getCompany(selectedCompanyName);
    if (!company) return;

    this.inputEl.value = company.name;

    this.dispatchEvent(new CustomEvent('drb-clearbit-company-selected', {
      detail: {
        company
      },
      bubbles: true,
      composed: true
    }));
  }

  render() {
    return html`
      <drb-type-ahead
        ${ref(this.typeAheadRef)}
        @drb-type-ahead-updated=${this._updateVisibility}
        query-path="https://autocomplete.clearbit.com/v1/companies/suggest"
        query-key="query"
        external
      >
        <drb-dropdown
          ${ref(this.dropdownRef)}
          @change=${this._selectCompany}
          no-toggle-open
        >
          <slot></slot>

          <div
            data-type-ahead-content
            slot="dropdown-content"
          ></div>
        </drb-dropdown>
      </drb-type-ahead>
    `;
  }
}

export { DrbClearbit };
