/*
https://www.notion.so/dribbble/drb-dialog-3dd8337ce6fc4407bba0e2614127dd80

A modal/dialog component that wraps/styles the native <dialog> element.

```
  <drb-dialog id="example-dialog">
    Content
  </drb-dialog>
```

Attributes:
  - `prevent-light-dismiss` (Boolean) - prevents the dialog from closing when clicking outside of it (default: false)
  - `drawer` (Boolean) - styles the dialog as a drawer (default: false)
  - `remote-url` (String) - fetches the content from the given URL when the dialog is opened
*/

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import {
  LitElement, unsafeCSS, html,
} from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { customElement, property, query, state } from 'lit/decorators.js';
import { ref, createRef, Ref } from 'lit/directives/ref.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import closeIcon from '~/assets/icons/close-v2.svg?raw';
import { animationsComplete, onNextRepaint } from '~/shared/utils/animation';
import styles from './drb-dialog.scss?inline';

@customElement('drb-dialog')
class DrbDialog extends LitElement {
  static styles = unsafeCSS(styles);
  private _fetchAbortController = new AbortController();

  dialogRef: Ref<HTMLDialogElement> = createRef();

  @query('[data-dialog-wrapper]') dialogWrapper: HTMLElement;

  // reactive properties
  @property({ attribute: 'mounting', reflect: true, type: Boolean })
  isMounting = true;

  @property({ attribute: 'open', reflect: true, type: Boolean })
  isOpen = false;

  @property({ attribute: 'prevent-light-dismiss', type: Boolean })
  preventLightDismiss = false;

  @property({ attribute: 'remote-url', type: String })
  remoteUrl = '';

  @property({ attribute: 'remote-should-reload', type: Boolean })
  remoteShouldReload = true;

  @property({ type: Boolean })
  drawer = false;

  @property({ attribute: false, type: String })
  returnValue = '';

  // internal state
  @state()
  isDraggingFromDialog = false;

  @state()
  isFetchingRemote = false;

  @state()
  hasRemoteError = false;

  // prevent the browser from playing the dialog open animation on page load
  async firstUpdated() {
    await animationsComplete(this.dialogRef.value);
    this.isMounting = false;

    if (this.isOpen) this.open();
  }

  // add global event listener for opening a dialog via `data-dialog-open`
  // attribute with matching `id`
  //
  // e.g. `<button data-dialog-open="myDialogId">Nevermind</button>`
  connectedCallback() {
    super.connectedCallback();

    document.addEventListener('click', (e) => {
      const target = (e.target as HTMLElement).closest('[data-dialog-open]');

      if (target?.getAttribute('data-dialog-open') === this.id) {
        this.open();
      }
    });
  }

  // add shadow root event listener for closing a dialog via `data-dialog-close`
  // attribute with optional `returnValue`
  //
  // e.g. `<button data-dialog-close="optional return value">Nevermind</button>`
  protected createRenderRoot() {
    const root = super.createRenderRoot();

    root.addEventListener('click', (e) => {
      const target = (e.target as HTMLElement).closest('[data-dialog-close]');
      if (target) this.close(target.getAttribute('data-dialog-close'));
    });

    return root;
  }

  open() {
    // remote dialogs should fetch content on open if it hasn't already or if force reload is true
    const shouldFetchRemote = this.remoteUrl && (this.remoteShouldReload || !this.hasContent());
    if (shouldFetchRemote) this.fetchRemoteUrl();

    this.dialogRef.value?.showModal();
    this.isOpen = true;

    // remove default focus on dialog open to prevent `focus-within` styles in certain cases
    (document.activeElement as HTMLElement)?.blur?.();

    disableBodyScroll(this.dialogRef.value, {
      reserveScrollBarGap: true,
    });

    // emit & track events
    this.dispatchEvent(new CustomEvent('drb-dialog-opened', {
      bubbles: true,
      composed: true
    }));
  }

  async fetchRemoteUrl() {
    // cancel any existing fetch requests
    if (this.isFetchingRemote) {
      this._fetchAbortController.abort('fetch cancelled');
      this._fetchAbortController = new AbortController();
    }

    // reset state
    this.isFetchingRemote = true;
    this.hasRemoteError = false;
    this.clearContent();

    // fetch remote content
    try {
      const response = await fetch(this.remoteUrl, {
        method: 'GET',
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        signal: this._fetchAbortController.signal
      });

      if (!response.ok) throw new Error('Error fetching remote content');

      // if the dialog has been closed before the fetch completes, don't render the content
      if (!this.isOpen) return;

      // render the remote content
      const html = await response.text();
      this.setContent(html);
    } catch (error) {
      this.hasRemoteError = true;
      console.error(error);
    } finally {
      this.isFetchingRemote = false;
    }
  }

  clearContent() {
    Array.from(this.children).forEach((child) => {
      if (!child.hasAttribute('slot')) child.remove();
    });
  }

  hasContent() {
    return Array.from(this.children).some((child) => !child.hasAttribute('slot'));
  }

  setContent(html: string) {
    this.clearContent();
    this.insertAdjacentHTML('beforeend', html);

    this.dispatchEvent(new CustomEvent('drb-dialog-content-updated', {
      bubbles: true,
      composed: true
    }));
  }

  close(returnValue?: string) {
    this.dialogRef.value?.close(returnValue);
  }

  private lightDismiss(e) {
    const path = e.composedPath();
    const isKeyboardEvent = e.detail === 0;
    const isInsideWrapper = path.includes(this.dialogWrapper)
    const isInsidePhotoswipe = path.some((el) => el.classList?.contains('pswp'));

    if (this.preventLightDismiss || this.isDraggingFromDialog || isKeyboardEvent || isInsideWrapper || isInsidePhotoswipe) return;
    this.dialogRef.value?.close('dismiss');
  }

  private _onNativeDialogClose() {
    this.returnValue = this.dialogRef.value?.returnValue;
    this.isOpen = false;
    enableBodyScroll(this.dialogRef.value);

    // emit & track events
    this.dispatchEvent(new CustomEvent('drb-dialog-closed', {
      bubbles: true,
      composed: true
    }));
  }

  render() {
    return html`
      <dialog
        ${ref(this.dialogRef)}
        class="dialog ${classMap({ 'dialog--drawer': this.drawer, 'dialog--fetching-remote': this.isFetchingRemote })}"
        @click="${this.lightDismiss}"
        @mouseup="${() => { onNextRepaint(() => { this.isDraggingFromDialog = false; }); }}"
        @close="${this._onNativeDialogClose}"
      >
        <div data-dialog-wrapper class="dialog__wrapper" @mousedown="${() => { this.isDraggingFromDialog = true; }}">
          <button class="dialog__close" @click="${this.close}">
            ${unsafeHTML(closeIcon)}
          </button>

          <slot name="remote-error" ?hidden=${!this.hasRemoteError}>
            Sorry, something went wrong.
          </slot>

          <slot name="remote-loading" ?hidden=${!this.isFetchingRemote}>
            <div class="dialog__loading"></div>
          </slot>

          <slot></slot>
        </div>

        <slot name="dialog-outer"></slot>
      </dialog>
    `;
  }
}

export { DrbDialog };
